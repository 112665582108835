import React from 'react';
import Iframe from 'react-iframe';
import { addWorkflow, emptyWorkflow, getWorkflow } from '../../redux/workflow/actions';
import { connect } from 'react-redux';
function XrVrComponent(currentWorkflow){

    const xrvr_url = window.__RUNTIME_CONFIG__.REACT_APP_XR_VR_URL+currentWorkflow.currentWorkflow;
    return (
        <>
            {currentWorkflow.currentWorkflow !='' ? (
                <Iframe
                    url={xrvr_url}
                    width='100%'
                    styles={{ border: 'none', height: '100vh' }}
                    id=""
                    className=""
                    display="block"
                    position="relative"
                />
            ) : (
                <div style={{ textAlign: 'center', paddingTop: '50vh' }}>
                    <p>Please select a workflow.</p>
                </div>
            )}
        </>
    );
}
const mapStateToProps = (state) => {
    return {
      currentWorkflow: state.workflow.currentWorkflow,
  
    };
  };
  
  export default connect(mapStateToProps, { addWorkflow, emptyWorkflow,getWorkflow })(XrVrComponent);
  