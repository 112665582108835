import React, { useEffect, useState } from 'react';
import {
  UserOutlined,
  BookOutlined,
  HomeOutlined,
  GlobalOutlined,
  HistoryOutlined,
  LogoutOutlined,
  CodeOutlined,
  DashboardOutlined,
  LineChartOutlined,
  NotificationOutlined,
  FileTextOutlined,
  InfoCircleOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Menu, Row, Col } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import logo from './../logo.svg';
import { useKeycloak } from '@react-keycloak/web';

function getItem(label, key, icon, path, children = undefined, type, style = {}) {
  return {
    key,
    icon,
    path,
    children: Array.isArray(children) ? children : undefined,
    label: path ? <Link to={path}>{label}</Link> : label,
    type,
    style,
  };
}

const SideBar = (props) => {
  const id = props.menu; // Passing menu item key
  const { keycloak } = useKeycloak();
  const [notificationData, setNotificationData] = useState(null);
  const navigate = useNavigate();

  const fetchKafkaData = async () => {
    try {
      const response = await fetch(window.__RUNTIME_CONFIG__.REACT_APP_API_ROOT_URI + '/Kafka/monitoring');
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (!data.name || !data.level || !data.description) {
        setNotificationData(null);
      } else {
        setNotificationData({ name: data.name, level: data.level, description: data.description });
      }
    } catch (error) {
      setNotificationData(null);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(fetchKafkaData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const OnMenuItemClick = (e) => {
    if (e.key === 'account') {
      window.open(keycloak.createAccountUrl(), '_self');
    } else if (e.key === 'logout') {
      window.open(keycloak.createLogoutUrl(), '_self');
    } else {
      const selectedItem = items.find((item) => item.key === e.key);
      if (selectedItem && selectedItem.path) {
        navigate(selectedItem.path);
      }
    }
  };

  const notificationIcon = (level) => {
    switch (level) {
      case 'CRITICAL':
        return <CloseCircleOutlined />;
      case 'WARNING':
        return <WarningOutlined />;
      case 'SUCCESS':
        return <CheckCircleOutlined />;
      case 'INFO':
      default:
        return <InfoCircleOutlined />;
    }
  };

  const notificationItems = notificationData
    ? [
        getItem(`Name: ${notificationData.name}`, 'notification-name', <NotificationOutlined />, null, null, null, {
          pointerEvents: 'none',
        }),
        getItem(
          `Level: ${notificationData.level}`,
          'notification-level',
          notificationIcon(notificationData.level),
          null,
          null,
          null,
          {
            pointerEvents: 'none',
          },
        ),
        getItem(
          `Description: ${notificationData.description}`,
          'notification-description',
          <FileTextOutlined />,
          null,
          null,
          null,
          {
            pointerEvents: 'none',
          },
        ),
      ]
    : [
        getItem('No Notifications', 'no-notification', <NotificationOutlined />, null, null, null, {
          pointerEvents: 'none',
        }),
      ];

  const items = [
    { type: 'divider' },
    getItem('Home', '1', <DashboardOutlined />, '/home'),
    { type: 'divider' },
    getItem('Data Visualization', '11', <LineChartOutlined />, '/analytics'),
    getItem('Map Visualization', '13', <GlobalOutlined />, '/map-visualization'),
    { type: 'divider' },
    getItem('Console', '20', <CodeOutlined />, '/console'),
    { type: 'divider' },
    getItem('History', '3', <HistoryOutlined />, '/history'),
    getItem('Account', 'account', <UserOutlined />),
    getItem('User Guide', '19', <BookOutlined />, '/user-guides'),
    { type: 'divider' },
    getItem('Sign Out', 'logout', <LogoutOutlined />),
    { type: 'divider' },
    ...notificationItems,
  ];

  return (
    <div>
      <div
        style={{
          height: 160,
          padding: 20,
          borderBottomRightRadius: 10,
        }}
      >
        <Row justify='space-around' align='middle'>
          <Col span={20}>
            <img src={logo} alt='logo' style={{ mixBlendMode: 'plus-lighter' }} />
          </Col>
        </Row>
      </div>
      <Menu defaultSelectedKeys={[id]} mode='inline' theme='dark' items={items} onClick={(e) => OnMenuItemClick(e)} />
    </div>
  );
};

export default SideBar;
