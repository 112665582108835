import React, {useState, useEffect} from 'react';
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    Legend,
  } from 'recharts';
import MainLayout from '../layouts/MainLayout';
import { getCsvFileContentFromBucket } from '../services/S3BucketService';
import { Button } from 'antd';
import { showErrorMessage } from '../common/utils';
import UploadComponent from '../components/UploadComponent';
  
  export default function AnalyticsView() {

    const [graphData, SetGraphData] = useState([]);
    const bucketName = "july-integration";
    const filename = "EO DataSet1.csv";

    const graph_title = "File found in " + bucketName + " named " + filename;

    /* useEffect(() => {

      getCsvFileContentFromBucket(bucketName, filename)
      .then((response) => {

        var formatedRawData = [];

        for (let i = 0; i < response.length; i++) {
            const item = response[i];
            
            formatedRawData.push({
                timestamp: item.TimeStamp,
                temperature: item.Temp,
                pressure: item.Pressure,
                humidity: (item.Humidity === '') ? 0 : item.Humidity,
                precipitation: (item.Precipitation === '') ? 0 : item.Precipitation,
                wind: (item.wind === '') ? 0 : item.wind 
            });
        }

        SetGraphData({ data: formatedRawData });
      })
      .catch((err) => {
        showErrorMessage(JSON.stringify(err))
      }) 
    }, []) */

    return (
        <MainLayout menu="11">
            {/* <ResponsiveContainer width="100%" height="100%">
                <LineChart
                width={500}
                height={300}
                data={graphData}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="timestamp" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="temperature" stroke="#8884d8"/>
                <Line type="monotone" dataKey="pressure" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer> */}
            <UploadComponent/>
        </MainLayout>
      );
    }