import React, { useEffect, useState, useRef } from "react";
import L from "leaflet";
import { FeatureGroup, useMap } from "react-leaflet";
import EditControl from "./EditControl";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Interweave } from 'interweave';
import { Card, Button, Modal } from 'antd';
export default function DrawControl({ isActive, polygon, onCordsChange }) {
  const map = useMap();
  const _editableFG = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const swapOrder = (coordinates) => {
    return coordinates.map((polygon) =>
      polygon.map(([longitude, latitude]) => [latitude, longitude])
    );
  };
  const sendData = (coordinates) => {
    onCordsChange(coordinates);
  };

  const isLayerWithinFirstLayerBounds = (layer, firstLayer) => {
    const layerBounds = layer.getBounds();
    const firstLayerBounds = firstLayer.getBounds();

    return firstLayerBounds.contains(layerBounds);
  };

  const _onCreated = (e) => {
    const { layerType, layer } = e;

    const firstLayer = Object.values(_editableFG.current._layers)[0]; // Assumes the first layer is the one at index 0

    if (isLayerWithinFirstLayerBounds(layer, firstLayer)) {

      // convert GeoJson coordinates to array
      var coordsArr = layer._latlngs;

      // for each segment
      var i = 0;
      var lnPts = "";
      while (i + 1 < coordsArr.length) {

        // get the start and end coordinates for the line
        var lineStartPoint = L.latLng(coordsArr[i]);
        var lineEndPoint = L.latLng(coordsArr[i + 1]);
        lnPts = [lineStartPoint, lineEndPoint];


        // counter increment
        i++;
      }
      // sendData((layer.toGeoJSON().geometry.coordinates)); // Assuming you want to send coordinates of the newly created layer
      var takis = L.geoJSON(layer.toGeoJSON(), {
        coordsToLatLng: function (coords) {
          //                    latitude , longitude, altitude
          //return new L.LatLng(coords[1], coords[0], coords[2]); //Normal behavior
          return new L.LatLng(coords[0], coords[1], coords[2]);
        }
      });
      // Get the first layer key
      const firstLayerKey = Object.keys(takis._layers)[0];

      // Get the first layer
      const firstLayer = takis._layers[firstLayerKey];

      // Assuming the first layer is a GeoJSON layer, check if it has the feature property
      if (firstLayer.feature) {
        // Assuming the feature property contains the GeoJSON data
        const geojsonData = firstLayer.feature;
        sendData((geojsonData.geometry.coordinates)); // Assuming you want to send coordinates of the newly created layer
      } else {
        console.error("The first lsssayer is not a GeoJSON layer.");
      }

    } else {
      setIsOpen(true);
      map.removeLayer(layer);
    }
  };

  const _onEdited = (e) => {
    const { layers: { _layers } } = e;
    const firstLayer = Object.values(_editableFG.current._layers)[0]; // Assumes the first layer is the one at index 0

    Object.values(_layers).forEach(({ _leaflet_id }) => {
      const editedLayer = _layers[_leaflet_id];
      if (!isLayerWithinFirstLayerBounds(editedLayer, firstLayer)) {
        setIsOpen(true);
        // Rollback the changes made during editing by reloading the first layer
        _editableFG.current.clearLayers();
        _editableFG.current.addLayer(firstLayer);
        map.fitBounds(firstLayer.getBounds());
        return;
      }

      sendData((editedLayer.toGeoJSON().geometry.coordinates)); // Assuming you want to send coordinates of the edited layer
    });
  };

  const _onFeatureGroupReady = (reactFGref) => {
    if (polygon) {
      let leafletGeoJSON = new L.GeoJSON(polygon, {
        style: {
          fillColor: 'red',
          color: 'red',
          weight: 2,
          fillOpacity: 0.1

        }
      });

      if (Object.keys(reactFGref._layers).length === 0) {
        leafletGeoJSON.eachLayer((layer) => {
          reactFGref.addLayer(layer);
        });
      } else {
        leafletGeoJSON.eachLayer((layer) => {
          if (layer._leaflet_id === 31) {
            reactFGref.addLayer(layer);
          }
        });
      }

      _editableFG.current = reactFGref;
    }
  };
  const handleCancel = () => {
    setIsOpen(false)
  };

  const handleOk = () => {
    setIsOpen(false)
  };

  useEffect(() => {
    if (_editableFG.current) {
    }
  }, [_editableFG]);

  return (

    <FeatureGroup
      ref={(reactFGref) => {
        if (reactFGref) _onFeatureGroupReady(reactFGref);
      }}
      eventHandlers={{
        add(e) {
          map.fitBounds(e.target.getBounds());
        }
      }}
    >
      <EditControl
        position="topright"
        onEdited={_onEdited}
        onCreated={_onCreated}
        draw={{
          circle: false,
          circlemarker: false,
          polyline: false
        }}
        leaflet={{ map }}
      />
      <Modal title="Error" open={isOpen} onOk={handleOk} onCancel={handleCancel} width={1200}>
        <p>The edited shape is outside the permitted bounds</p>
      </Modal>
    </FeatureGroup>

  );
}
