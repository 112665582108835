import React from 'react';
import MainLayout from '../layouts/MainLayout';
import ConsoleComponent from './console/ConsoleComponent';
import ProductWF from '../components/ProductWF';

function ProductsView(){

    return (
        <MainLayout menu="28">
            <ProductWF/>
        </MainLayout>
    )
}

export default ProductsView;