import React from 'react';
import MainLayout from '../layouts/MainLayout';
import ConsoleComponent from './console/ConsoleComponent';
import ProductWF from '../components/ProductWF';
import Cart from '../components/Cart';

function CartView(){

    return (
        <MainLayout menu="20">
            <Cart/>
        </MainLayout>
    )
}

export default CartView;