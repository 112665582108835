import React, {useState, useEffect} from 'react';
import {Timeline, Drawer, Card, Button, Tag} from 'antd';
import MainLayout from '../layouts/MainLayout';
import { getUserHistory} from '../services/userService';
import { useKeycloak } from '@react-keycloak/web';

function HistoryView(){

    const {keycloak} = useKeycloak();
    const [historyRecords, setHistoryRecords] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setOpen(true);
      };
    const onClose = () => {
        setOpen(false);
    };

    
    const ShowInDrawer = (e, item) => {
        console.log(item)
        setSelectedItem(item);
        showDrawer();
    }

    const getColor = (status) =>{
        if(status === 'OK - 200'){
            return 'green';
        }
        else{
            return 'red';
        }
    }

    useEffect(() => {
      getUserHistory(keycloak.token, keycloak.subject)
       .then((response) =>{
        
        const its = [];

        for (let i = 0; i < response.length; i++) {
            const element = response[i];

            its.push({
                label: new Date(element.dateRecorded).toLocaleString(),
                children: (<>
                    <b>{element.requestURI}</b> 
                    <Button id={i} size={'small'} onClick={(e) => ShowInDrawer(e,element)} style={{marginLeft: 10}}>View</Button>
                </>),
                color: getColor(element.status)
            });
            
        }

        setHistoryRecords(its)
       })

    
    }, [])
    

    return (
        <MainLayout menu="3">
            <Timeline
                reverse={false}
                mode='left'
                items={historyRecords}
                
            />
            {selectedItem !== null &&
                <Drawer
                title={(<>Data Viewer <br/> <b style={{fontSize: 12}}>{selectedItem.requestURI}</b>  <Tag color={getColor(selectedItem.status)}>{selectedItem.status}</Tag></>)}
                placement='right'
                closable={true}
                onClose={onClose}
                open={open}
                key={1}
                push={true}
                width={500}
            >
                <Card>
                    <pre>
                        {JSON.stringify(JSON.parse(selectedItem.historyData), null, 2)}
                    </pre>
                </Card>
            </Drawer>
            }
        </MainLayout>
    )
}

export default HistoryView;