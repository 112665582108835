// components/ProductList.js
import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { addItem, deleteItem, getItems } from '../redux/product/actions';
import { AddCart, DeleteCart, } from '../redux/cart/actions';
import PolygonMap from '../components/polygonmaps/polygonMap';

import { Row, Col, Space, Typography, Card, Collapse, Button } from 'antd';
import {
    ShoppingCartOutlined, CopyOutlined, CloseOutlined
} from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
const { Panel } = Collapse;
const { Text } = Typography;

const Product = ({ products,cart, addItem, AddCart, deleteItem }) => {
    return (
        <Row justify={'space-between'} style={{ margin: 16 }}>

            {
                products.map((item, key) => (
                    <Col span={12} key={key} style={{ marginBottom: 16, paddingRight: 16 }} >
                        <Card title={
                            <Row justify={'space-between'}>
                                <Col span={14}><Text strong style={{ color: '#418cc0', fontSize: 18 }}> {item.title} </Text></Col>
                                {item.quantity == 0 &&
                                    <>
                                        <Col span={8} offset={0}> <>    <Button type="primary"  style={{  marginRight: 16 }} onClick={() => AddCart(item)} icon={<ShoppingCartOutlined />}>
                                            Add to WF Creator
                                        </Button>
                                        </>

                                  <Button type="danger" onClick={() => deleteItem(item)} icon={<CloseOutlined />}>
                                        
                                    </Button>

                                    </Col>
                                </>
                                }

                            </Row>
                        }>
                            <Collapse accordion>
                                <Panel header="Python Script" key={key + 'python'} extra={
                                    <>
                                        <CopyToClipboard text={item.pythonScript} >
                                            <CopyOutlined style={{ color: '#418cc0' }} />
                                        </CopyToClipboard>
                                    </>}>
                                    <pre style={{ maxHeight: 400, overflowY: 'scroll' }}><code>{item.pythonScript}</code>
                                    </pre>
                                </Panel>
                                <Panel header="Meta Info" key={key + 'meta'} extra={
                                    <>
                                        <CopyToClipboard text={item.metaJSON} >
                                            <CopyOutlined style={{ color: '#418cc0' }} />
                                        </CopyToClipboard>
                                    </>}>
                                    <pre style={{ maxHeight: 400, overflowY: 'scroll' }}><code>{item.metaJSON}</code>
                                    </pre>
                                </Panel>
                                <Panel header="Geometry" key={key + 'geometries'}>
                                    <PolygonMap polygonCords={[JSON.stringify(item.geometry[0])]} zoomSize={3} drawing={false} selectedPolygon={[]} ></PolygonMap>
                                </Panel>
                            </Collapse>
                        </Card>

                    </Col>

                ))
            }
        </Row>

    );
};

const mapStateToProps = state => {
    return {
        cart: state.cart.Carts,
        products: state.products.items,
    }
};

const mapDispatchToProps = {
    AddCart,
    deleteItem,
    getItems
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
