import { Row, Col, Card, Input, Select, Form } from 'antd';
import React, { useState, useEffect } from 'react';
import { getAllS3Bucket, getBucketFiles } from '../services/S3BucketService';
import GeoMap from './geomaps/Map';
import { connect } from 'react-redux';
import { useKeycloak } from '@react-keycloak/web';
import { addWorkflow, emptyWorkflow, getWorkflow } from '../redux/workflow/actions';

function MapsComponent({ addWorkflow, emptyWorkflow, currentWorkflow }) {
  const [bucketFiles, setBucketFiles] = useState([]);
  const [selectedFileDataNames, setSelectedFileDataNames] = useState([]);
  const { keycloak } = useKeycloak();

  useEffect(() => {
    loadBucketFiles(currentWorkflow);
  }, [currentWorkflow]);

  const loadBucketFiles = (name) => {
    getBucketFiles(keycloak.token, name).then((resp) => {
      const files = resp
        .filter(
          (item) =>
            item.name.toLowerCase().endsWith('.tif') ||
            item.name.toLowerCase().endsWith('.tiff') ||
            item.name.toLowerCase().endsWith('.nc') ||
            item.name.toLowerCase().endsWith('.zip'),
        )
        .map((item) => {
          const extension = item.name.split('.').pop();
          return {
            label: item.name,
            value: JSON.stringify({
              id: item.name,
              type: extension,
              url: item.presignedUrl,
            }),
          };
        });
      setBucketFiles(files);
    });
  };

  const handleSelectedFilesChange = (values) => {
    setSelectedFileDataNames(values);
  };

  const [form] = Form.useForm();

  return (
    <>
      <Form form={form}>
        <Card>
          <Row style={{ marginBottom: 30 }}>
            <Col span={14}>
              <Form.Item
                label='Data Files'
                help={<i style={{ fontSize: 10 }}>Only files with extensions '.tif', '.tiff', '.nc', '.zip' are allowed</i>}
                required={true}
              >
                <Select
                  mode='multiple'
                  showSearch
                  placeholder='Select Data files'
                  style={{ width: '100%' }}
                  optionFilterProp='children'
                  filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  options={bucketFiles}
                  onChange={handleSelectedFilesChange}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
      <br />
      {selectedFileDataNames.length > 0 && (
        <Card>
          <div className='grid-container'>
            <GeoMap selectedFiles={selectedFileDataNames} zoomSize={3} />
          </div>
        </Card>
      )}
    </>
  );
}
const mapStateToProps = (state) => ({
  currentWorkflow: state.workflow.currentWorkflow,
});

export default connect(mapStateToProps, { addWorkflow, emptyWorkflow, getWorkflow })(MapsComponent);
