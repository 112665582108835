import React from 'react';
import Iframe from 'react-iframe';


function ConsoleComponent(){

    const cli_url = window.__RUNTIME_CONFIG__.REACT_APP_CONSOLE_URI

    return(
        <>
            <Iframe url={cli_url}
                width='100%'
                styles={{border:'none', height: '100vh'}}
                id=""
                className=""
                display="block"
                position="relative"/>
        </>
    )
}

export default ConsoleComponent;