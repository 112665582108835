import React, {useState, useEffect} from 'react';
import MainLayout from '../layouts/MainLayout';
import WikiComponent from '../components/WikiComponent';
  
  export default function WikiView() {
    return (
        <MainLayout menu="19">
            <WikiComponent/>
        </MainLayout>
      );
    }