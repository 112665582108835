import React, {useState, useRef,useEffect} from 'react';
import {Row, Button, Modal, Form, Input, Tooltip, Col, Tabs} from 'antd';
import {CopyOutlined} from '@ant-design/icons'
import Iframe from 'react-iframe';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Base64 } from 'js-base64';
import { showErrorMessage, showSuccessMessage } from '../../common/utils';
import { createWorkflow } from '../../services/workflowService';
import { useKeycloak } from '@react-keycloak/web';

const { TextArea } = Input;

function WorkflowEditorComponent({ pythonScript, metaJson }){

    const {keycloak} = useKeycloak();

    const workflow_editor_url = window.__RUNTIME_CONFIG__.REACT_APP_WORKFLOW_EDITOR_URI;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDefaultValueCopied, setDefaultValueCopied] = useState(false);

    const [defaultQuery, setDefaultQuery] = useState(`import cdsapi

    c = cdsapi.Client()
    
    c.retrieve(
    'Global bioclimatic indicators from 1950 to 2100 derived from climate projections',
    {
    'derived_variable': 'monthly_sum',
    'ensemble_member': 'r1i1p1',
    'experiment': 'rcp8_5',
    'model': 'gfdl_esm2m',
    'temporal_aggregation': 'monthly',
    'variable': ['frost_days','growing_degree_days',],
    'version': '1.0',
    'format': 'zip',
    },
    'download.zip')`);

    const initialItems = [
        {
          label: 'Product 1',
          children: (
            <>
                <Form.Item label="Query" name="workflow_query"
                    rules={[
                    {
                    required: true
                    }
                    ]}>
                      
                    <TextArea value={pythonScript} rows={10} placeholder='Python Script to be executed...'/>
                </Form.Item>
                <Form.Item label="Meta" name="workflow_meta"
                    rules={[
                    {
                    required: true
                    }
                    ]}>
                    <TextArea value={metaJson} rows={7} placeholder='JSON meta data here...'/>
                </Form.Item>
            </>
          ),
          key: '1',
          closable: false
        },
      ];

      const [activeKey, setActiveKey] = useState(initialItems[0].key);
      const [items, setItems] = useState(initialItems);
      const newTabIndex = useRef(1);
      const onChange = (newActiveKey) => {
        setActiveKey(newActiveKey);
      };
      const add = () => {
        const newActiveKey = `newTab${newTabIndex.current++}`;
        const newPanes = [...items];
        newPanes.push({
          label: 'Product '+ newTabIndex.current,
          children: (
            <>
            <Form.Item label="Query" name={"workflow_query-" + newTabIndex.current} help={tips}
                rules={[
                {
                required: true
                }
                ]}>
                <TextArea rows={10} placeholder='Python Script to be executed...'/>
            </Form.Item>
            <Form.Item label="Meta" name={"workflow_meta-" + newTabIndex.current}
                rules={[
                {
                required: true
                }
                ]}>
                <TextArea rows={7} placeholder='JSON meta data here...'/>
            </Form.Item>
            </>
          ),
          key: newActiveKey,
        });
        setItems(newPanes);
        setActiveKey(newActiveKey);
      };
      const remove = (targetKey) => {
        let newActiveKey = activeKey;
        let lastIndex = -1;
        items.forEach((item, i) => {
          if (item.key === targetKey) {
            lastIndex = i - 1;
          }
        });
        const newPanes = items.filter((item) => item.key !== targetKey);
        if (newPanes.length && newActiveKey === targetKey) {
          if (lastIndex >= 0) {
            newActiveKey = newPanes[lastIndex].key;
          } else {
            newActiveKey = newPanes[0].key;
          }
        }
        setItems(newPanes);
        setActiveKey(newActiveKey);
      };
      const onEdit = (targetKey, action) => {
        if (action === 'add') {
          add();
        } else {
          remove(targetKey);
        }
      };

    const [form] = Form.useForm();

    const onCopyDefaultQuery = () =>{
        setDefaultValueCopied(true)
        showSuccessMessage("Default Query copied into clipboard!")
    }

    const tips = (
        <Row style={{marginTop:10, marginBottom: 10}}>
            <Col span={22}>
                Don't have a query value? Copy this default value
            </Col>
            
            <Col span={2}>
                <CopyToClipboard text={defaultQuery} onCopy={onCopyDefaultQuery}>
                    <Tooltip title="Click to copy default value" color='#418cc0'>
                        <CopyOutlined style={{color: '#418cc0'}}/>
                    </Tooltip>
                </CopyToClipboard>
            </Col>
        </Row>
    );
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
         form
          .validateFields()
          .then((values) => {
            
            //console.log(values);

            var workflowName = values.workflow_name;
            var queries = [];
            var meta = [];

            queries.push(Base64.encode(values.workflow_query));
            meta.push(JSON.parse(values.workflow_meta));

            for (const key in values) {
              if (Object.hasOwnProperty.call(values, key)) {
                const element = values[key];
                
                if (key.includes("workflow_query-")){
                  queries.push(Base64.encode(element));
                }
                else if(key.includes("workflow_meta-")){
                  meta.push(JSON.parse(element));
                }
              }
            }

            var bodyContent = {};

            // if(queries.length === 1 && meta.length === 1){
            //     bodyContent = {
            //         name: workflowName,
            //         query: queries,
            //         meta: Base64.encode(meta[0])
            //     }
            // }
            // else if (queries.length > 1){
            //     bodyContent = {
            //       name: workflowName,
            //       query: queries,
            //       meta: Base64.encode(meta)
            //   }
            // }
            bodyContent = {
              name: workflowName,
              query: queries,
              meta: Base64.encode(JSON.stringify(meta.flat()))
          }

            console.log(bodyContent);

            let data = JSON.stringify(bodyContent);

            createWorkflow(keycloak.token, data)
            .then((response) => {
                setIsModalOpen(false);
                showSuccessMessage(JSON.stringify(response));
            })
            .catch((error) => {
                showErrorMessage("Oups! Error "+ JSON.stringify(error))
            })
            //showSuccessMessage("Real Sociedad!")
          }); 
        //setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);

        form.resetFields();
        setItems(initialItems);
        setActiveKey(initialItems[0].key)
    };
    useEffect(() => {
    }, [pythonScript,metaJson,form])
    return(
        <>
            <Iframe url={workflow_editor_url}
                width='100%'
                styles={{border:'none', height: '100vh'}}
                id=""
                className=""
                display="block"
                position="relative"/>

        </>
    )
}

export default WorkflowEditorComponent;