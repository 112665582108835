import React, {useState, useEffect} from 'react';
import MainLayout from '../layouts/MainLayout';
import MapsComponent from '../components/MapsComponent';
  
  export default function MapsView() {
    return (
        <MainLayout menu="13">
            <MapsComponent/>
        </MainLayout>
      );
    }