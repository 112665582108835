import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { IncreaseQuantity, DecreaseQuantity, DeleteCart, EmptyCart } from '../redux/cart/actions';
import { Table, Button, Input, Form, Card } from 'antd';
import { Base64 } from 'js-base64';
import { showErrorMessage, showSuccessMessage, showWarningMessage } from '../common/utils';
import { createWorkflow } from '../services/workflowService';
import { useKeycloak } from '@react-keycloak/web';
import { KgWorkflowContext } from '../views/DashboardView.js';
import { v4 as uuidv4 } from 'uuid';
import { generatePath, useNavigate } from 'react-router-dom';

function Cart({ items, IncreaseQuantity, EmptyCart, DeleteCart }) {
  const [workflowName, setWorkflowName] = useState('');
  console.log(items);
  let ListCart = [];
  let TotalCart = 0;
  Object.keys(items.Carts).forEach(function (item) {
    TotalCart += items.Carts[item].quantity;
    ListCart.push(items.Carts[item]);
  });
  const { keycloak } = useKeycloak();
  const { count, tabId, pythonScript, metaJson, tabSet, reset, decrement, increment } = useContext(KgWorkflowContext);
  const useNavigateParams = () => {
    const navigate = useNavigate();

    return (url, params) => {
      const path = generatePath('/?tabKey=WE', {
        url,
        queryString: params,
      });
      navigate(path);
    };
  };
  function TotalPrice(price) {
    return Number(price).toLocaleString('en-US');
  }
  const navigate = useNavigateParams();

  const createWF = () => {
    if (!workflowName.trim()) {
      showErrorMessage('Please enter a workflow name before creating a workflow.');
      return;
    }

    if (workflowName.length > 50) {
      showErrorMessage('Workflow name should not exceed 50 characters.');
      return;
    }

    var values = ListCart;
    console.log(values);
    var queries = [];
    var meta = [];
    ListCart.forEach((item, index) => {
      queries.push(Base64.encode(item.pythonScript));
      meta.push(Array(JSON.parse(item.metaJSON)));
    });

    for (const key in values) {
      if (Object.hasOwnProperty.call(values, key)) {
        const element = values[key];

        if (key.includes('workflow_query-')) {
          queries.push(Base64.encode(element));
        } else if (key.includes('workflow_meta-')) {
          meta.push(JSON.parse(element));
        }
      }
    }

    var bodyContent = {
      name: workflowName ? workflowName : uuidv4(),
      query: queries ? queries : '',
      meta: meta ? Base64.encode(JSON.stringify(meta.flat())) : '',
    };

    let data = JSON.stringify(bodyContent);
    if (values.length === 0) {
      showWarningMessage('You are about to create an empty workflow');
      setTimeout(() => {
        createWorkflow(keycloak.token, data)
          .then((response) => {
            showSuccessMessage(JSON.stringify(response));
            EmptyCart();
          })
          .catch((error) => {
            showErrorMessage('Oops! Error ' + JSON.stringify(error));
          });
      }, 3000);
    } else {
      createWorkflow(keycloak.token, data)
        .then((response) => {
          showSuccessMessage(JSON.stringify(response));
          EmptyCart();
        })
        .catch((error) => {
          showErrorMessage('Oops! Error ' + JSON.stringify(error));
        });
    }
  };
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="danger" onClick={() => DeleteCart(record.key)}>
          Delete
        </Button>
      ),
    },
  ];

  const data = ListCart.map((item, index) => ({
    ...item,
    key: index,
    totalPrice: TotalPrice(item.quantity),
  }));

  return (
    <div className="row">
      <div className="col-md-12">
        <Form layout="inline" style={{ marginBottom: '20px' }}>
          <Card>
            <Form.Item label="Workflow Name">
              <Input
                id="workflowName"
                name="workflowName"
                placeholder="Enter workflow name"
                style={{ width: '300px' }}
                value={workflowName}
                onChange={(e) => setWorkflowName(e.target.value)}
              />
            </Form.Item>
          </Card>
        </Form>
        <Table
          columns={columns}
          dataSource={data}
          footer={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Total WF Products: {Number(TotalCart).toLocaleString('en-US')}</span>
              <Button type="primary" onClick={() => createWF()}>
                Create Workflow
              </Button>
            </div>
          )}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    items: state.cart,
  };
};

export default connect(mapStateToProps, { IncreaseQuantity, DecreaseQuantity, DeleteCart, EmptyCart })(Cart);
