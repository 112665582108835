import React from 'react';
import MainLayout from '../layouts/MainLayout';

function PreferencesView(){

    return (
        <MainLayout menu="2">
            Preferences View
        </MainLayout>
    )
}

export default PreferencesView;