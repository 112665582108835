import React from 'react';
import { Layout, Menu, Breadcrumb, Card, Button, Collapse, Image } from 'antd';
import { Link } from 'react-router-dom';
import ReactAntdown from 'react-antdown'
import rehypeRaw from 'rehype-raw';
import CustomImage from '../hooks/CustomImage';
import CustomHeaderHook from '../hooks/CustomHeaderHook';
import accountImage from '../assets/images/Account.png';
import accountSettingsImage from '../assets/images/Account_settings.png';
import consoleImage from '../assets/images/console.png';
import dataVisualisationImage from '../assets/images/Data_Visualisation.png';
import fileExplorerImage from '../assets/images/file_explorer.png';
import historyImage from '../assets/images/History.png';
import kg1Image from '../assets/images/KG1.png';
import kg2Image from '../assets/images/KG2.png';
import kg3Image from '../assets/images/KG3.png';
import kg4Image from '../assets/images/KG4.png';
import kg5Image from '../assets/images/KG5.png';
import kg6Image from '../assets/images/KG6.png';
import kg7Image from '../assets/images/KG7.png';
import kg8Image from '../assets/images/KG8.png';
import kg9Image from '../assets/images/KG9.png';
import mapVisualisationImage from '../assets/images/Map_Visualisation.png';
import nvdiImage from '../assets/images/nvdi.png';
import registerImage from '../assets/images/register.png';
import screenshot1Image from '../assets/images/Screenshot_2024-05-28_at_2.10.19_PM.png';
import screenshot2Image from '../assets/images/Screenshot_2024-05-28_at_2.10.25_PM.png';
import screenshot3Image from '../assets/images/Screenshot_2024-05-28_at_2.10.39_PM.png';
import screenshot4Image from '../assets/images/Screenshot_2024-05-28_at_2.11.03_PM.png';
import screenshot5Image from '../assets/images/Screenshot_2024-05-28_at_2.11.32_PM.png';
import screenshot6Image from '../assets/images/Screenshot_2024-05-28_at_2.12.03_PM.png';
import screenshot7Image from '../assets/images/Screenshot_2024-05-28_at_2.12.21_PM.png';
import screenshot8Image from '../assets/images/Screenshot_2024-05-28_at_2.12.48_PM.png';
import screenshot9Image from '../assets/images/Screenshot_2024-05-28_at_2.15.48_PM.png';
import signinImage from '../assets/images/signin.png';
import slide1Image from '../assets/images/Slide1.jpg';
import slide2Image from '../assets/images/Slide2.jpg';
import slide3Image from '../assets/images/Slide3.jpg';
const someMarkdown = `The EO4U [Web Portal](https://dashboard.apps.eo4eu.eu/home) consists of the following:

## Table of Contents
1. [Account](#account)
2. [Dashboard](#dashboard)
   1. [Search Engine](#search-engine)
   2. [Workflow Editor](#workflow-editor)
      1. [Views](#views)
         1. [Workflows List](#workflows-list)
         2. [Workflow Editor](#workflow-editor-1)
      2. [Fusion Functions](#fusion-functions)
         1. [Normalized difference vegetation index (NDVI)](#normalized-difference-vegetation-index-ndvi)
         2. [Merge Bands S2](#merge-bands-s2)
         3. [Merge Rasters](#merge-rasters)
         4. [Hours to Days (ERA5)](#hours-to-days-era5)
         5. [Kernel Density Estimation](#kernel-density-estimation)
      3. [ML Models](#ml-models)
         1. [[UC5] Soil-Erosion (C-factor prediction)](#uc5-soil-erosion-c-factor-prediction)
         2. [[UC6] Food (Locust)](#uc6-food-locust)
   3. [File Explorer](#file-explorer)
   4. [Results Visualisation](#results-visualisation)
      1. [Map Visualization](#map-visualization)
      2. [Data Visualization](#data-visualization)
      3. [Extended Reality (XR) Visualization tool](#extended-reality-xr-visualization-tool)
3. [Console](#console)
4. [User History](#user-history)

For a detailed look of the platform in action check [here](Showcase).
<span id="account"></span>
## Account 

To access the EO4EU platform, the user must register to the platform. Once you access the Web Portal for the first time, you will see the sign-in screen.

![signin](${signinImage})

As you can see, there is a register button that you can press to register yourself as a new user.

![register](${registerImage})

Once you have registered and you can access the Web Portal, there is a section "Account" on the left menu

![Account](${accountImage})

which allows you to see/edit your personal details and see the applications that you have access to.

![Account_settings](${accountSettingsImage})

<span id="dashboard"></span>
## Dashboard

<span id="search-engine"></span>
### Search Engine

The first step in using the EO4EU platform is to search for the data needed. One of the platform's main contributions is that it allows semantic data search through various data sources, including the option to upload datasets. Once the user logs in to the portal, the first accordion section is entitled "Knowledge Graph", which is the tool used to search through data sources semantically.

From the drop-down menu, the user can choose the data source and provide a keyword.

![KG1](${kg1Image})

Once the data source and keyword are defined, the user can search using the search button on the top right and the most matching results will be shown.

![KG2](${kg2Image})

Once the user chooses the dataset of interest, a specialised menu provides more details/options. The example for the ADAM data source includes a description of the dataset, its variables, file formats, and data types.

![KG3](${kg3Image})

Additionally, another section of this menu is the ADAM Advanced Filter where the user can choose the area of interest on the map.

![KG4](${kg4Image})

Once the area is defined, the user can choose the date ranges and filter them to identify the correct products to download.

![KG5](${kg5Image})

When the products are chosen, the user uses the Generate Script button to create the Python Script and the Meta Info JSON that will be passed to the Workflow Editor Component.

![KG6](${kg6Image})

Each set of products is saved in the "My WF Products" section at the top of the Portal and they can be accessed by pressing that button.

![KG7](${kg7Image})

The user can then choose the products they want to pass to the Workflow Editor by adding the to the WF Creator using the "Add to WF Creator" button. The products are then moved to the WF Creator that can be accessed by pressing the button at the top of the Portal. From there, the user can create a workflow using the "Create Workflow" button.

![KG8](${kg8Image})

Once that button is pressed, a new workflow is created inside the Workflow Editor with the specific products.

![KG9](${kg9Image})

<span id="workflow-editor"></span>
### Workflow Editor

WFE is a component of the eu4eu platform that a user can manage (publish, delete and save as draft) a workflow
<span id="views"></span>
#### Views

In the application there are two basic views. The view that the user can see and manage all the workflows that have been created, as well as their status and the editing view.
<span id="workflow-list"></span>
##### Workflows List

![Screenshot_2024-05-28_at_2.10.19_PM](${screenshot1Image})

The status of each workflow is visible in each workflow item. All possible statuses are:
- draft
- compiling
- compiled
- publishing
- published
- stopping
- stopped
- error

![Screenshot_2024-05-28_at_2.10.39_PM](${screenshot2Image})

With 3-dots menu, in the top right corner of the item, a user can see publish, view or delete an item depending on the state of this.

![Screenshot_2024-05-28_at_2.10.25_PM](${screenshot3Image})

<span id="workflow-editor-1"></span>
##### Workflow Editor

The editor is divided into three main sections. The top bar where the user can save his work or publish it. The editing canvas and the marketplace of the components available, on the left, for insertion into the workflow.

![Screenshot_2024-05-28_at_2.11.03_PM](${screenshot4Image})

**Items in the marketplace are searchable**

![Screenshot_2024-05-28_at_2.11.32_PM](${screenshot5Image})

A workflow building process is by drag-and-drop items from the marketplace to the canvas and connecting them.

![Screenshot_2024-05-28_at_2.12.03_PM](${screenshot6Image})

![Screenshot_2024-05-28_at_2.12.21_PM](${screenshot7Image})

Editable items have a 3-dot menu. Clicking on it opens a new window in which the user can edit the parameters of each component.

![Screenshot_2024-05-28_at_2.12.48_PM](${screenshot8Image})

![Screenshot_2024-05-28_at_2.15.48_PM](${screenshot9Image})
<span id="fusion-functions"></span>
#### Fusion Functions

Fusion Algorithms can be selected in Workflow Editor. In v1 a user can select 6 fusion algorithms and can be combined in a more complicated workflow.
<span id="normalized-difference-vegetation-index-ndvi"></span>
##### Normalized difference vegetation index (NDVI)

The algorithm calculates the normalized difference vegetation index as
![nvdi](${nvdiImage})


The result has values between -1 and 1. The user selects the NIR and RED bands (at the moment only Sentinel-2 products) that will be used as inputs for the calculation. This step will add the calculated band to the current list of bands.

<span id="merge-bands-s2"></span>
##### Merge Bands S2

This algorithm merges selected Sentinel-2 bands to create a new composite product.

<span id="merge-rasters"></span>
##### Merge Rasters

This algorithm merges raster layers from multiple sources into a single raster layer.

<span id="hours-to-days-era5"></span>
##### Hours to Days (ERA5)

This algorithm converts hourly data from ERA5 into daily data.

<span id="kernel-density-estimation"></span>
##### Kernel Density Estimation

A spatial analyst tool that calculates the density of features in a neighborhood around those features.

<span id="kernel-density-estimation"></span>
#### ML Models

In this section, there are predefined ML models available.

<span id="uc5-soil-erosion-c-factor-prediction"></span>
##### [UC5] Soil-Erosion (C-factor prediction)

ML model that uses EO data to predict C-factor for soil erosion.

<span id="uc6-food-locust"></span> 
##### [UC6] Food (Locust)

ML model that uses EO data to predict locust outbreaks.

<span id="file-explorer"></span>
### File Explorer

The File Explorer allows the user to browse through uploaded and generated files. The File Explorer contains a hierarchical directory structure that the user can navigate.

![File_Explorer](${fileExplorerImage})

Users can upload new files and manage their files and directories.

<span id="results-visualisation"></span>
### Results Visualisation

Once the workflow processing has been completed, whether raw or processed data, the output files can be visualised in three ways. If the results are geotiffs, they can be visualised on maps by overlaying layers in 2D through the "Map Visualisation" component. The results can be visualised on graphs or tables through the "Data Visualisation" component if the results are arithmetic data. Finally, the platform provides the option to visualise the data in a 3D mode through the XR/VR component. More details on the three components can be found in the following subsections.

<span id="map-visualization"></span>
#### Map Visualization

The Map Visualization tool allows users to visualize geospatial data on a map. Users can overlay different datasets and use various tools to analyze the spatial data.
![Map_visualization](${mapVisualisationImage})

<span id="data-visualization"></span>
#### Data Visualization

The Data Visualization tool provides various charts and graphs to help users understand and analyze their data. Users can customize their visualizations and save them for future reference.
![data_visualization](${dataVisualisationImage})

<span id="extended-reality-xr-visualization-tool"></span>
#### Extended Reality (XR) Visualization tool

This tool allows the user to explore the output data and the intermediate results of a specific workflow in an Extended Reality (XR) environment, namely Virtual Reality (VR) environment and Augmented Reality (AR) environment. Currently it is available on the dashboard only the possibility to visualize the data in a Virtual Reality environment.
To enable the XR visualization on a specific workflow, during its creation within the Workflow Editor application, the AR/XR task needs to be linked to the data output task as shown in the following image:

![xr_vr](${slide1Image})

When a specific workflow is selected using select workflow section in the dashboard, if the XR visualization was enabled during the creation phase of the workflow, the VR visualization of its resulting data is available opening the XR/VR tab in the dashboard Home section.
In the VR interface home page, all the areas covered by the data resulting from the selected workflow are displayed as selectable bounding boxes on a globe. If a device supporting VR sessions is detected, a button appears on the top-right corner of the application and can be used to start a VR session.

![xr_vr2](${slide2Image})

Selecting a specific bounding box, it is possible to visualize the data related to the selected area jointly with a basic set of metadata.
![xr_vr3](${slide3Image})

During a VR session a wrist mounted User Interface is available to navigate between the different data layers and display the metadata information.


<span id="console"></span>
## Console

The Console provides a command-line interface for advanced users to interact with the EO4EU platform. Users can execute scripts and commands directly from the console.
![console](${consoleImage})

<span id="history"></span>
## User History

The User History section keeps track of all user activities and workflows. Users can view their history to review past actions and results.
![history](${historyImage})


`
const renderers = {
   img: CustomImage,
   span:CustomHeaderHook
 };
const WikiComponent = () => (
    <Layout style={{ minHeight: '100vh' }}>
        <Card>
            <ReactAntdown rehypePlugins={[rehypeRaw]} components={renderers}>
                {someMarkdown}
            </ReactAntdown></Card>
    </Layout>
);

export default WikiComponent;
