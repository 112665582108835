import { notification } from "antd";

export const showSuccessMessage = (msg) =>{
    notification.success({
      message: 'Success!',
      placement: 'bottomRight',
      duration: 5, //time in seconds
      description: msg
    });
  }
  export const showWarningMessage = (msg) =>{
    notification.warning({
      message: 'Warning!',
      placement: 'top',
      duration: 3, //time in seconds
      description: msg
    });
  }
  export const showErrorMessage = (msg) =>{
    notification.error({
      message: 'Error!',
      placement: 'bottomRight',
      duration: 5, //time in seconds
      description: msg
    });
  }

  export const tableColumnsBuilderFromArray = (jsonArray) =>{

    if (jsonArray.length === 0) {
      return [];
    }
    let columns = []
    for (var prop in jsonArray[0]) {
        if (jsonArray[0].hasOwnProperty(prop)) {
            columns.push({
                dataIndex: prop.toLowerCase(),
                title: prop,
                key: prop.toLowerCase()
            })
        }
    }
    return columns;
  }

  export const tableDatasourceBuilderFromArray = (jsonArray) =>{

    let datasource = [];

    for (let i = 0; i < jsonArray.length; i++) {
      var item = {
        key: i
      };

      for (const key in jsonArray[i]) {
        if (Object.hasOwnProperty.call(jsonArray[i], key)) {
          const prop_value = jsonArray[i][key];
          
          item[key.toLowerCase()] = prop_value
        }
      }

      datasource.push(item)
    }
    return datasource;
  }