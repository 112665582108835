import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClockCircleOutlined, MailOutlined,  ShoppingCartOutlined, SettingOutlined } from '@ant-design/icons';
import { Badge } from 'antd';

function Header(props) {
    return (    <Link to="/cart" className="nav-link">Workflow Creator <Badge size="small"  count={props.numberCart}><ShoppingCartOutlined  style={{ fontSize: 20 }}/>
  </Badge></Link>);
};

const mapStateToProps = state => {
    return {
        numberCart: state.cart.numberCart
    };
};

export default connect(mapStateToProps)(Header);
