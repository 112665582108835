import React, { useState, useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import { Button, Table, Space, Drawer, Card, Row, Col, Tabs, Popconfirm, Select, Tag, Input, Divider, Typography, Collapse, Cascader, Form, List } from 'antd';
import { CheckCircleOutlined, SyncOutlined, MinusCircleOutlined } from '@ant-design/icons';
//import Graph from 'react-vis-network-graph';
import { connect } from 'react-redux';
import { addItem, deleteItem, getItems } from '../redux/product/actions';
import { AddCart, DeleteCart, } from '../redux/cart/actions';
import { useKeycloak } from '@react-keycloak/web';

import { deleteStoreData, getStoreDataByRange } from '../services/StoreDataService';
import { showErrorMessage, showSuccessMessage } from '../common/utils';
import { executeQuery, executeQueryAdvanced, generateApiCall, getKG_ServiceStatus, searchDataSet, searchDataSetBreakdownPerId, startKG_Service, searchDataSetV1, searchDataSetV2, searchFullMetaData, searchBasicMetaDataSet } from '../services/kg_DataService';

import MarketPlaceComponent from './dashboard/MarketPlaceComponent';
import WorkflowEditorComponent from './dashboard/WorkflowEditorComponent';
import KgDetailedComponent from './dashboard/KgDetailedComponent';
import CopyToClipboard from 'react-copy-to-clipboard';
import XrVrComponent from './xrvr/XrVrComponent';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  generatePath,
  Link,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useContext, createContext } from 'react';

const initialCount = 0;
export const KgWorkflowContext = createContext({ count: initialCount, tabId: [],pythonScript:null,metaJson:null});
const { Paragraph } = Typography;

const { Panel } = Collapse;

const { Search } = Input;

const variableColumns = [
  {
    title: 'Name',
    dataIndex: 'Name',
    key: 'name',
  },
  {
    title: 'Mapping',
    dataIndex: 'Mapping',
    key: 'mapping',
  },
  {
    title: 'Unit',
    dataIndex: 'Unit',
    key: 'unit',
  },
];


function DashboardView(productsWF) {

  const [kgwf, setKgwf] = useState(initialCount);
  const [activePanels, setActivePanels] = useState(['WE']);

  const reset = () => setKgwf(initialCount);
  const decrement = () => setKgwf((prevCount) => prevCount - 1);
  const increment = () => setKgwf((prevCount) => prevCount + 1);
  const tabSet=(value) => setKgwf(value);
  const [form] = Form.useForm();

  const columns = [
    {
      title: 'Record ID',
      dataIndex: 'dummyRecordId',
      key: 'id',
    },
    {
      title: 'Recorded At',
      dataIndex: 'dateRecorded',
      key: 'date',
      filters: [
        {
          text: 'Today',
          value: new Date().toLocaleDateString(),
        },
        {
          text: 'Yesterday',
          value: () => {
            var date = new Date();
            date.setDate(date.getDate() - 1)

            return date.toLocaleDateString()
          },
        },
      ],
      onFilter: (value, record) => record.dateRecorded.includes(value),
      render: (_, record) => {
        return new Date(record.dateRecorded).toLocaleString();
      }
    },
    {
      title: 'Values',
      dataIndex: 'values',
      align: 'right',
      key: 'values',
      render: (_, record) => (
        <Space size="middle" key={record.dummyRecordId} >
          <Button onClick={(e) => ShowStoreDataInModal(e, record)}>View Data</Button>
        </Space>
      )
    },
  ];


  const [open, setOpen] = useState(false);

  const [records, setRecords] = useState([]);

  const [serviceStatus, SetServiceStatus] = useState("Stopped");

  const [selected, SetSelected] = useState({
    dummyRecordId: '',
    dummyRecordData: '{}'
  });

  const [searchResults, setSearchResults] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [serviceStarted, setServiceStarted] = useState(false);


  const [searchedDataSet, SetSearchedDataSet] = useState([]);

  const [datasetInformations, SetDatasetInformations] = useState([]);

  const [datasetTags, SetRelatedDatasetTags] = useState([]);

  const [selectedDatasetBreakDown, SetSelectedDatasetBreakDown] = useState("");

  const [dataset_api_download_options, SetDownloadOptions] = useState([]);

  const [selectedDataSetId, SetSelectedDataSetId] = useState(null);
  const [selectedDataSetPersistentId, SetSelectedDataSetPersistentId] = useState(null);

  const [datasets_api_options, setDatasetApiOptions] = useState([]);

  const [selectedDownloadOption, SetSelectedDownloadOptions] = useState("");
  const [selectedMetaInfoFields, SetSelectedMetaInfoFields] = useState([]);
  const { keycloak } = useKeycloak();

  const [prb_items, SetPrbItems] = useState();

  // const [ selectedTemporalAggregationOptions, SetSelectedTemporalAggregationOptions] = useState([]);
  // const [temporalAggOptions, SetTemporalAggOptions] = useState([]);

  const [jsonMeta, SetJsonMeta] = useState({});

  const [isSearching, SetIsSearching] = useState(false);

  const [openDetailed, SetOpenDetailed] = useState(false);

  const [PassedDatasetInformation, SetPassedDatasetInformation] = useState({})

  const [configDetails, SetApiConfigDetails] = useState({})

  const [selectedSearchedSources, SetSelectedSearchedSources] = useState([]);
    const { search } = useLocation();

  useEffect(() => {
    //setServiceStarted(true)
    setActivePanels(kgwf.tabId);

    getStoreDataByRange(keycloak.token)
      .then((resp) => {
        setRecords(resp);
        setIsLoading(false);
      });
  }, [kgwf])

  useEffect(() => {
    getKG_ServiceStatus(keycloak.token)
      .then((resp) => {
        SetServiceStatus(resp.payload);
        if (resp.payload === 'Available') {
          setServiceStarted(true);
        }
      })
  }, [])



  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onChangePanel  = (activeKey) => {
    setActivePanels(activeKey);
  };

  const ShowStoreDataInModal = (e, record) => {
    SetSelected(record);
    showDrawer();
  }

  const reloadData = () => {
    setIsLoading(true);
    getStoreDataByRange(keycloak.token)
      .then((resp) => {
        setRecords(resp);
        showSuccessMessage('Data refreshed successfully!');
        setIsLoading(false);
      })
  }

  const onResetField = () => {

  }

  const OnServiceStart = () => {
    setIsLoading(true)

    startKG_Service(keycloak.token)
      .then((resp) => {
        if (resp === 'Done') {
          setIsLoading(false);
          setServiceStarted(true);
        }
      })

  }

  const onSearchQuery = (value) => {

    const query = value;

    if (query !== '') {

      SetIsSearching(true);

      if (selectedSearchedSources.length > 0) {
        executeQueryAdvanced(keycloak.token,query, selectedSearchedSources)
          .then((resp) => {
            if (resp !== '' || resp != null || resp != undefined) {
              var fake = resp.payload;
              if (Array.isArray(fake) && fake.length >= 0) {
                setSearchResults(fake);

                var dataset_options = [];

                for (let j = 2; j < fake.length + 2; j++) {
                  var i = j - 2;
                  const element = fake[i];
                  dataset_options.push({
                    label: "ID: " + element,
                    value: element
                  })
                }

                SetSearchedDataSet(dataset_options);

                searchDataSet(keycloak.token,fake, true)
                  .then((response) => {
                    var results = response.payload;
                    console.log('yessssss',results);
                    var ds = [];

                    for (let index = 0; index < results.length; index++) {
                      var dataset = results[index].metadata;
                      console.log(results[index].persistentID,dataset['FileFormat'],'inside');
                      ds.push({
                        id: results[index].id,
                        persistentId: results[index].persistentID,
                        title: dataset['Title'][0].values[0],
                        description: dataset['Description'][0].values[0],
                        isfromadam: (dataset['Source'][0].values[0] == 'adam')?true : false,
                        isfromSentinel: (dataset['Source'][0].values[0] == 'sentinel')?true : false,
                        content: (
                          <>
                              <Tag color="blue" key={'item'}>
                                {'item'}
                              </Tag>
                          </>
                        ),                        
                        fileformats: dataset['FileFormat'] != undefined ? (
                          <>
                            {dataset['FileFormat'] && dataset['FileFormat'][0].values.map((item) => (
                              <Tag color={'cyan'} key={item}>
                                {item}
                              </Tag>
                            ))}
                          </>
                        ): (
                          <>
                              <Tag color={'red'}>
                                {'undefined'}
                              </Tag>
                          </>
                        ),

                        source: (
                          <>
                              <Tag color="blue" key={dataset['Source'][0].values[0]}>
                                {dataset['Source'][0].values[0]}
                              </Tag>
                          </>
                        ),
                      })
                    }

                    SetDatasetInformations(ds);

                    SetIsSearching(false);

                  })
                  .catch((error) => {
                    console.log(error);
                    SetIsSearching(false);
                    showErrorMessage("We are sorry! We can't find results for this query, please try again later")
                  })
              }
              else {
                setSearchResults([]);
                SetDatasetInformations([]);
                SetIsSearching(false);
                showErrorMessage("We are sorry! We were unable to find results for '" + query + "'");
              }
            }
            else {
              SetIsSearching(false);
              showErrorMessage("We are sorry! We can't find results for this query, please try again later")
            }
          })
          .catch((error) => {
            SetIsSearching(false);
            showErrorMessage(error);
          })
      }
      else {
        executeQuery(keycloak.token,query)
          .then((resp) => {
            if (resp !== '' || resp != null || resp != undefined) {
              var fake = resp.payload;
              if (Array.isArray(fake) && fake.length >= 0) {
                setSearchResults(fake);

                var dataset_options = [];

                for (let j = 2; j < fake.length + 2; j++) {
                  var i = j - 2;
                  const element = fake[i];
                  dataset_options.push({
                    label: "ID: " + element,
                    value: element
                  })
                }

                SetSearchedDataSet(dataset_options);

                searchDataSet(keycloak.token,fake, true)
                  .then((response) => {
                    var results = response.payload;
                    var ds = [];

                    for (let index = 0; index < results.length; index++) {
                      var dataset = results[index].metadata;
                      ds.push({
                        id: results[index].id,
                        persistentId: results[index].persistentID,
                        title: dataset['Title'][0].values[0],
                        description: dataset['Description'][0].values[0],
                        isfromadam: (dataset['Source'][0].values[0] == 'adam')?true : false,
                        isfromSentinel: (dataset['Source'][0].values[0] == 'sentinel')?true : false,
                        content: (
                          <>
                              <Tag color="blue" key={'item'}>
                                {results[index].persistentID}
                              </Tag>
                          </>
                        ),
                        fileformats: dataset['FileFormat'] != undefined ? (
                          <>
                            {dataset['FileFormat'] && dataset['FileFormat'][0].values.map((item) => (
                              <Tag color={'cyan'} key={item}>
                                {item}
                              </Tag>
                            ))}
                          </>
                        ): (
                          <>
                              <Tag color={'red'}>
                                {'undefined'}
                              </Tag>
                          </>
                        ),

                        source: (
                          <>
                              <Tag color="blue" key={dataset['Source'][0].values[0]}>
                                {dataset['Source'][0].values[0]}
                              </Tag>
                          </>
                        ),
                      })
                    }

                    SetDatasetInformations(ds);

                    SetIsSearching(false);

                  })
                  .catch((error) => {
                    console.log(error);
                    SetIsSearching(false);
                    showErrorMessage("We are sorry! We can't find results for this query, please try again later")
                  })
              }
              else {
                setSearchResults([]);
                SetDatasetInformations([]);
                SetIsSearching(false);
                showErrorMessage("We are sorry! We were unable to find results for '" + query + "'");
              }
            }
            else {
              SetIsSearching(false);
              showErrorMessage("We are sorry! We can't find results for this query, please try again later")
            }
          })
          .catch((error) => {
            SetIsSearching(false);
            showErrorMessage(error);
          })

      }

    }

  }

  const onchangeMetaDataOptions = (value) => {
    SetSelectedMetaInfoFields(value);
    console.log(value);
  };
  const DeleteStoreRecords = () => {
    setIsLoading(true);
    deleteStoreData(keycloak.token)
      .then((resp) => {
        showSuccessMessage('All records have been deleted successfully!')
        reloadData();
        setIsLoading(false);
      })
  }

  const CurrentTag = () => {
    if (serviceStatus === 'Available') {
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {serviceStatus}
        </Tag>
      );
    }

    if (serviceStatus === 'Loading') {
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {serviceStatus}
        </Tag>
      );
    }

    if (serviceStatus === 'Stopped') {
      return (
        <Tag icon={<MinusCircleOutlined />} color="error">
          {serviceStatus}
        </Tag>
      );
    }
  }

  const SearchResults = () => {
    if (serviceStatus === 'Available' && searchResults.length > 0) {
      return (
        <Paragraph >
          <Divider />
          <p>We found a total of <b>{searchResults.length}</b> datasets</p>
          {/* <Graph graph={graphData} options={nodeOptions} events={events} style={{ height: 600 }} /> */}
        </Paragraph>
      )
    }
    else {
      return (
        <Paragraph >
          <Divider />
          <p>No Results were found! Please try another query</p>
        </Paragraph>
      )
    }
  }

  const handleChangeDataSetId = (value) => {
    searchDataSet(keycloak.token,value)
      .then((response) => {
        console.log(response);
        SetDatasetInformations(response.results);
      })
  }

  const onchangeDownloadOptions = (value) => {
    SetSelectedDownloadOptions(value)
  }

  const onPrbChange = (key) => {
    console.log(key)
  }

  const onOpenDetailedDataset = (datasetId,persistentId) => {
    console.log(datasetId,persistentId.persistentId,'takis')
      GetAndSetDetailedDatasetInfo(datasetId);
      if (!PassedDatasetInformation.isfromadam && !PassedDatasetInformation.isfromSentinel) {
          GetAndSetBreakdonwDataset(datasetId);
      }
    
    SetSelectedDataSetPersistentId(persistentId.persistentId);
    SetSelectedDataSetId(datasetId);    SetOpenDetailed(true);

  }

  const GetAndSetDetailedDatasetInfo = (datasetId) => {
    var ids = [];
    ids.push(datasetId);

    var res = searchDataSet(keycloak.token,datasetId, false)
      .then((response) => {
        var results = response.payload;
        var ds = [];

        for (let index = 0; index < results.length; index++) {
          var dataset = results[index].metadata;
          console.log(results[index]);
          ds.push({
            id: results[index].id,
            persistentId: results[index].persistentID,
            title: dataset['Title'][0].values[0],
            description: dataset['Description'][0].values[0],
            isfromadam: (dataset['Source'][0].values[0] == 'adam')?true : false,
            isfromSentinel: (dataset['Source'][0].values[0] == 'sentinel') ? true : false,
            content: (
              <>
                  <Tag color="blue" key={'item'}>
                    {'item'}
                  </Tag>
              </>
            ),
            fileformats:dataset['FileFormat'] != undefined ? (
              <>
                {dataset['FileFormat'] && dataset['FileFormat'][0].values.map((item) => (
                  <Tag color={'cyan'} key={item}>
                    {item}
                  </Tag>
                ))}
              </>
            ): (
              <>
                  <Tag color={'red'}>
                    {'undefined'}
                  </Tag>
              </>
            ),

            source: (
              <>
                  <Tag color="blue" key={dataset['Source'][0].values[0]}>
                    {dataset['Source'][0].values[0]}
                  </Tag>
              </>
            ),
          })
        }

        SetPassedDatasetInformation(ds[0]);
        console.log(PassedDatasetInformation);
      })
      .catch((error) => {
        console.log(error);
        showErrorMessage("We are sorry! We can't find results for this query, please try again later")
        SetPassedDatasetInformation({});
      })

  }

  const GetAndSetBreakdonwDataset = (id) => {
    searchDataSetBreakdownPerId(keycloak.token,id)
        .then((res) => {
          console.log("get and set breakdown dataset" , res)
        if (JSON.stringify(res) !== "{}" && JSON.stringify(res) !== JSON.stringify({ "Error": "Invalid input" })) {
            var result = JSON.parse(JSON.stringify(res));
            
            
            var download_option = [] 
            
            result.payload.metadata.DownloadOption[0].values.forEach(element => {
              download_option.push({
                label: element,
                value: element
              })
            });

            var products = result.payload.products;

            console.log("products", products)

          var productsTree = [];

          for (let j = 0; j < products.length; j++) {
            var features = products[j].features;
            var productId = products[j].id

            var node = {
              label: 'Product ' + productId,
              value: productId,
              key: j,
              children: features
            };

            productsTree.push(node);
            }
          console.log("product tree" , productsTree)

          console.log({
            json: result.payload,
          cascader: productsTree,
          download_options: download_option
        })

          SetApiConfigDetails({
              json: result.payload,
            cascader: productsTree,
            download_options: download_option
          });
        }
        else if (JSON.stringify(res) === JSON.stringify({ "Error": "Invalid input" })) {
            console.log("error in setting breakdown")
          SetApiConfigDetails({
            json: {},
            cascader: [],
            download_options: []
          });
        }
      })
        .catch((err) => {
            console.log(err)
            showErrorMessage(err);
            SetApiConfigDetails({
              json: {},
              cascader: [],
              download_options: []
            });
        });


      console.log("the setted breakdown..." , configDetails)
  }


  const options = [
    {
      value: 'Adam',
      label: 'Adam',
    },
    {
      value: 'Sentinel',
      label: 'Sentinel',
    },
    {
      value: 'ADS',
      label: 'ADS',
    },
    {
      value: 'CDS',
      label: 'CDS',
    },
    {
      value: 'Inspire',
      label: 'Inspire',
    },
    {
      value: 'Istat',
      label: 'Istat',
    },
    {
      value: 'Land',
      label: 'Land',
    },
    {
      value: 'Marine',
      label: 'Marine',
    },
    {
      value: 'Other',
      label: 'Other',
    }
  ];

  const onSelectQuerySources = (value) => {
    SetSelectedSearchedSources(value);

  }

  return (<KgWorkflowContext.Provider value={{ kgwf, reset, decrement, increment,tabSet }}>

    <MainLayout menu="1">

      <Collapse size='large' onChange={onChangePanel}       
      activeKey={activePanels}>
        {/* //Knowledge Graph Section */}
        <Panel header={'Knowledge Graph'} key="KG">
          <Row style={{ marginBottom: 10 }}>
            <Col span={22}>
              <CurrentTag />
            </Col>
            <Col span={2}>
              <Space>
                <Button type='primary' disabled={serviceStarted} onClick={OnServiceStart}>Start KG Service</Button>
              </Space>
            </Col>
          </Row>
          {serviceStarted === true &&
            <div>
              <Card title="Query Search">

                <Row>
                  <Col span={24}>
                    <Space.Compact style={{ width: '100%' }} size='large'>
                      <Select placeholder="Select Sources (Leave it blank means all options selected)" showSearch
                        style={{ width: '30%' }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={onSelectQuerySources}
                        multiple
                        maxTagCount="responsive"
                        mode="tags"
                        size='large'
                        options={options} />
                      <Search
                        placeholder="Enter a string to make a research e.g: 'climate change'"
                        allowClear
                        enterButton="Search"
                        style={{ width: '100%' }}
                        size="large"
                        onSearch={onSearchQuery}
                      />
                    </Space.Compact>
                  </Col>
                </Row>

                <SearchResults />
                <List
                  itemLayout="vertical"
                  loading={isSearching}
                  size="large"
                  pagination={{
                    onChange: (page) => {
                      console.log(page);
                    },
                    pageSize: 10,
                  }}
                  dataSource={datasetInformations}
                  renderItem={(item) => (
                    <List.Item
                      key={item.id}

                    // about={item.datatypes}
                    // content={item.fileformats}
                    >
                      <List.Item.Meta
                        title={<Link onClick={(e) => onOpenDetailedDataset(item.id, item, e)}> {item.title} </Link>}
                        description={item.description}

                      />
                      {item.source} |
                      <span>
                        <i style={{ marginLeft: 15 }}>From Adam: </i>
                        {item.isfromadam === true && <Tag color={'green'} key="0"> YES</Tag>}
                        {item.isfromadam === false && <Tag color={'red'} key="1"> NO</Tag>}
                      </span>
                    </List.Item>
                  )}
                />
              </Card>
              <br />
            </div>
          }
        </Panel>
        {/* //END Knowledge Graph Section */}

        {/* //Workflow editor Section */}
        <Panel header="Workflow Editor" key="WE">
          <WorkflowEditorComponent pythonScript={kgwf.pythonScript} metaJson={kgwf.metaJson} />
        </Panel>
        {/* //END Workflow editor Section */}

        {/* //Elastic Search Section */}
        {/* <Panel header="Elastic Search" key="ES">
          <p>Elastic Search Section</p>
        </Panel> */}
        {/* //END Elastic Search Section */}

        {/* //Bucket Explorer Section */}
        <Panel header="File Explorer" key="BM">
          <MarketPlaceComponent />
        </Panel>
        {/* //END ML Marketplace Section */}


        {/* //ML Marketplace Section */}
        {/* <Panel header="AI/ML Marketplace" key="ML">
          <p>Empty Section for now</p>
        </Panel> */}
        {/* //END ML Marketplace Section */}

        {/* //XR VR Section */}
        <Panel header="XR/VR" key="XR">
          <XrVrComponent />
        </Panel>
        {/* //END ML Marketplace Section */}

        {/* //Fake Store Section */}
        {/* <Panel header="Global Store Records" key="GS">
          <Row justify={'end'} style={{ marginBottom: 10 }}>
            <Col span={3}>
              <Space>
                <Button type='primary' onClick={reloadData}>Reload</Button>
                <Popconfirm
                  title="Delete all store data"
                  description="Are you sure to delete all the records found in the store?"
                  onConfirm={DeleteStoreRecords}
                  placement='bottom'
                  okText="Yes"
                  okType='danger'
                  cancelText="No"
                >
                  <Button danger={true}>Delete All</Button>
                </Popconfirm>
              </Space>
            </Col>
          </Row>
          <Table dataSource={records} columns={columns} loading={isLoading} />
        </Panel> */}
        {/* //END Fake Store Section */}
      </Collapse>

      <KgDetailedComponent datasetid={selectedDataSetId} persistentId={selectedDataSetPersistentId}dataset={PassedDatasetInformation} breakdown={configDetails} isopen={openDetailed} productsWF={productsWF} close={() => SetOpenDetailed(false)} />

      <Drawer
        title={(<>Store Data Viewer <br /> <b style={{ fontSize: 12 }}>{selected.dummyRecordId}</b></>)}
        placement='right'
        closable={true}
        onClose={onClose}
        open={open}
        key={1}
        push={true}
        width={500}
      >
        <Card>
          <pre>
            {JSON.stringify(JSON.parse(selected.dummyRecordData), null, 2)}
          </pre>
        </Card>
      </Drawer>
    </MainLayout>
  </KgWorkflowContext.Provider>

  );
}
const mapStateToProps = state => {
  return {
      cart: state.cart.Carts,
      productsWF: state.products.items,
  }
};

const mapDispatchToProps = {
  AddCart,
  deleteItem,
  getItems
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardView);