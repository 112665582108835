import wf_apiRequest from "../api/wf_instance";

export const getUserWorkflows = async (token) =>{
    
    const config = {
        method: 'GET',
        url: '/my/workflows?status=PUBLISHED&withCfs=true',
        headers: {
            "Authorization": 'Bearer ' + token
        }
    };

    return await wf_apiRequest(config);
}

